@import 'mantine';
.ticker {
  display: flex;
  text-align: left;
  align-items: center;
  gap: rem(16);
  @include body-small;
  @include elevation-3;
  background: var(--mantine-color-blue-4);
  color: var(--mantine-color-gray-0);
  padding: rem(8) rem(16);
  border-radius: rem(4);

  &:hover {
    text-decoration: none;

    & .pointer {
      text-decoration: underline;
    }
  }

  &:active {
    color: var(--mantine-color-gray-0);
  }

  @include larger-than($mantine-breakpoint-desktop-sm) {
    margin-top: rem(32);
  }
}

.icon {
  svg {
    display: block;
  }
}

.texts {
  display: flex;
  flex-direction: column;

  @include larger-than($mantine-breakpoint-desktop-sm) {
    flex-direction: row;
    gap: rem(16);
  }
}

.extraText {
  opacity: 0.75;
}

.pointer {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
  gap: rem(8);
}

.pointerText {
  display: none;

  @include larger-than($mantine-breakpoint-desktop-sm) {
    display: block;
  }
}
