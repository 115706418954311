@import 'mantine';
.banner {
  background-color: var(--mantine-color-yellow-0);
  display: flex;
  align-items: center;
  gap: rem(8);
  padding: rem(8) rem(16);

  // Body-small, but with less line-height
  font-size: rem(16);
  line-height: rem(24);
  color: var(--mantine-color-black);

  p {
    margin: 0;
  }
}

.icon {
  height: rem(24);
}

.content {
  flex: 1;
}

.close {
  height: rem(24);
  margin-left: auto;
  cursor: pointer;
}
