@import 'mantine';
.header {
  position: sticky;
  top: 0;
  z-index: 1001; // Higher than the viewer modals
  background-color: var(--mantine-color-gray-0);
  height: rem(72);
  display: flex;
  padding: 0 rem(16);
  align-items: center;
}

.mobileNavigation {
  overflow-y: scroll;
  position: fixed;
  top: rem(72);
  z-index: 1001; // Higher than the viewer modals
  bottom: 0;
  width: 100%;
  background-color: var(--mantine-color-gray-0);
  padding: 0 rem(24);

  @include larger-than($mantine-breakpoint-desktop-sm) {
    display: none;
  }
}

.mobileNavItem {
  display: flex;
  @include h3-mobile;
  padding-block: rem(16);
  border-bottom: 1px solid var(--mantine-color-gray-6);

  &:first-child {
    border-top: 1px solid var(--mantine-color-gray-6);
  }

  &:last-child {
    margin-bottom: rem(16);
  }
}

.withArrow {
  justify-content: space-between;
  align-items: center;
}
