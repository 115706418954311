@import 'mantine';
.hero {
  @include larger-than($mantine-breakpoint-desktop-sm) {
    overflow: visible;
  }
}

.heroContentWrapper {
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: rem(24);
  padding-bottom: rem(24);
  padding-inline: rem(16);

  @include larger-than($mantine-breakpoint-desktop-sm) {
    text-align: left;
    flex-direction: row;
    padding-inline: 0;

    align-items: center;
  }
}

.mobileHeroContent {
  display: contents;
}

.heroContent {
  display: contents;

  @include larger-than($mantine-breakpoint-desktop-sm) {
    display: block;
    position: static;
  }
}

.heroInnerContent {
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  height: 100%;
}

.textWrapper {
  display: flex;
  flex-direction: column;

  max-width: rem(408);
  height: 80%;
}

.tickerWrapper {
  align-self: stretch;
  margin-bottom: rem(16);
}

.titleAndSubtitle {
  flex-grow: 1;
}

.heroBgImg {
  display: block;
  position: relative;
  display: block;
  overflow: hidden;

  img {
    object-fit: cover;
    object-position: 85%;
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(
      0deg,
      rgba(0 57 164 / 50%) 0%,
      rgba(7 18 44 / 80%) 89%,
      rgba(8 13 29 / 90%) 100%
    );
    z-index: 1;
  }

  @include larger-than($mantine-breakpoint-desktop-sm) {
    display: block;
    background-color: var(--mantine-color-blue-5);
    overflow: hidden;

    &::before {
      display: none;
    }

    img {
      object-position: center;
    }
  }
}

.hiddenWrapper {
  overflow: hidden;
}

.buttonWrapper {
  will-change: transform, opacity;

  button {
    width: 100%;

    @include larger-than($mantine-breakpoint-desktop-sm) {
      width: fit-content;
    }

    div {
      margin: auto;
    }
  }
}

$hero-height: min(max(calc(100vh - rem(72) - rem(100)), rem(600)), 100vw);

.shadowDesktop {
  display: none;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  clip-path: polygon(
    0 0,
    calc(50vw - (($hero-height / 2) * tan(30deg))) 0,
    calc(50vw + (($hero-height / 2) * tan(30deg))) 100%,
    0 100%
  );
  background: linear-gradient(
      320deg,
      rgba(0 57 164 / 40%) 27.06%,
      transparent 60.94%
    ),
    radial-gradient(
      74.44% 77.54% at -20% 4.37%,
      rgba(0 57 164 / 29.2%) 25%,
      rgb(8 13 29 / 40%) 83.5%
    ),
    radial-gradient(
      74.44% 77.54% at -20% 4.37%,
      rgb(0 57 164 / 80%) 25%,
      rgb(8 13 29 / 80%) 83.5%
    );

  @include larger-than($mantine-breakpoint-desktop-sm) {
    display: block;
  }
}

.lines {
  display: block;
  position: absolute;
  bottom: 0;
  left: calc(50% - 120px);
  z-index: 1;

  @include larger-than($mantine-breakpoint-desktop-sm) {
    display: none;
  }
}

.linesDesktop {
  display: none;
  position: absolute;
  bottom: -202px;
  left: calc(50vw + (($hero-height / 2) * tan(30deg)) - 180px);

  @include larger-than($mantine-breakpoint-desktop-sm) {
    display: block;
  }
}
