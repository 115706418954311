@import 'mantine';
.mobileNavigation {
  overflow-y: scroll;
  position: fixed;
  top: rem(72);
  z-index: 10;
  bottom: 0;
  width: 100%;
  background-color: var(--mantine-color-gray-0);
  padding: 0 rem(24);
}

.lang {
  display: flex;
  justify-content: space-between;
  height: rem(64);
  align-items: center;
  border-bottom: 1px solid var(--mantine-color-gray-6);

  &:first-child {
    border-top: 1px solid var(--mantine-color-gray-6);
    margin-top: rem(16);
  }
}
