@import 'mantine';
.topBar {
  text-transform: uppercase;
  padding: 0 rem(16);
  justify-content: flex-end;
  height: rem(56);
  gap: rem(24);
  color: var(--mantine-color-gray-6);
  background-color: var(--mantine-color-blue-5);
  align-items: center;

  a,
  a:visited,
  a:active {
    color: var(--mantine-color-gray-6);
    text-decoration: none;
  }
}

.backLink {
  margin-right: auto;
  gap: rem(8);
  display: flex;
  align-items: center;
}
