@import 'mantine';
.policies {
  display: flex;
  margin: 0 auto;
  gap: rem(80);

  a {
    color: var(--mantine-color-blue-0);
  }
}

.credits {
  display: flex;
  justify-content: space-between;

  p {
    color: var(--mantine-color-gray-7);
  }
}

.lowerFooter {
  background-color: var(--mantine-color-gray-4);

  .policies {
    flex-direction: column;
    gap: rem(32);
    text-align: center;
  }

  .credits {
    flex-direction: column;
    align-items: center;
    gap: rem(32);
  }

  .implementedBy {
    flex-direction: column;
    align-items: center;
  }

  .logos {
    justify-content: center;
  }

  @include larger-than($mantine-breakpoint-desktop-sm) {
    .policies {
      flex-direction: row;
    }

    .credits {
      flex-direction: row;
    }

    .implementedBy {
      flex-direction: row;
    }

    .logos {
      justify-content: center;
    }
  }
}

.footer {
  background-color: var(--mantine-color-blue-0);
  color: var(--mantine-color-white);
  padding-block: rem(40);
  text-align: center;
  font-size: rem(16);
  line-height: rem(24);

  @include larger-than($mantine-breakpoint-desktop-sm) {
    text-align: left;
  }
}

.footerTitle {
  border-bottom: 1px solid var(--mantine-color-white);
  padding-bottom: rem(8);
  margin-bottom: rem(32);
  margin-top: rem(48);

  @include larger-than($mantine-breakpoint-desktop-sm) {
    @include h5-desktop;
    margin-bottom: rem(32);
    margin-top: rem(48);
  }
}

.copernicusService {
  color: var(--mantine-color-white);
}

.noticesInner {
  display: flex;
  flex-direction: column;
}

.funding {
  display: flex;
  align-items: center;
  gap: rem(8);
}

.social {
  margin-top: rem(48);
}

.socialTitle {
  @include body-extra-small-desktop;
  color: var(--mantine-color-gray-6);
}

.socialButtons {
  justify-content: center;

  @include larger-than($mantine-breakpoint-desktop-sm) {
    justify-content: flex-start;
  }
}

.aboutWekeoSection {
  display: inline-block;
  color: var(--mantine-color-gray-0);
  margin-block: rem(10);
}

.supportButton {
  cursor: pointer;
  font-size: rem(16);

  &:hover {
    text-decoration: underline;
  }
}
