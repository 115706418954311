@import 'mantine';
.header {
  background-color: var(--mantine-color-gray-0);
  height: rem(112);
  display: flex;
  padding: 0 rem(32);
  align-items: center;
}

.servicesAndToolsPopover {
  position: absolute;
  top: rem(150);
  width: rem(1216);
  right: calc(50% - rem(608));
  display: none;

  @include larger-than($mantine-breakpoint-desktop-sm) {
    display: flex;
    right: 50%;
    left: 50%;
    transform: translate(-50%, 0);
    max-width: calc(100% - rem(64));
  }

  @include larger-than($mantine-breakpoint-desktop) {
    max-width: rem(1216);
  }

  background-color: white;
  z-index: 10;
  box-shadow: 0 0 16px 1px #080d1d1a;
  box-shadow: 0 rem(24) rem(24) rem(12) #080d1d80;
}

.euFlag {
  @include larger-than($mantine-breakpoint-desktop) {
    width: rem(56);
    height: auto;
  }
}

.copernicusLogo {
  @include larger-than($mantine-breakpoint-desktop) {
    height: rem(48);
    width: auto;
  }
}

.wekeoLogo {
  @include larger-than($mantine-breakpoint-desktop) {
    height: rem(48);
    width: auto;
  }
}
