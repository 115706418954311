@import 'mantine';
.serviceName {
  color: var(--mantine-color-gray-7);
}

.dataViewerBlock {
  display: flex;
  align-items: center;
  overflow: hidden;
  background-color: var(--mantine-color-gray-0);

  & > div {
    flex-basis: 100%;
  }

  @include larger-than($mantine-breakpoint-desktop-sm) {
    height: rem(760);
  }
}

.dataViewerImage {
  max-width: calc(100% + rem(32));
  margin-inline: rem(-16);
  aspect-ratio: 1 / 1;
  overflow: hidden;
  object-fit: cover;

  @include larger-than($mantine-breakpoint-desktop-sm) {
    max-width: 100%;
    margin-inline: 0;
  }
}

.dataViewerContentWrapper {
  padding-top: rem(40);
  padding-bottom: rem(40);

  @include larger-than($mantine-breakpoint-desktop-sm) {
    padding: var(--grid-col-padding);
  }
}

.dataViewerImageColumn {
  position: relative;
  padding-bottom: 0;
  width: 100%;
  height: 100%;

  @include larger-than($mantine-breakpoint-desktop-sm) {
    padding-bottom: var(--grid-col-padding);
  }
}

.dataViewerImageWrapper {
  width: 100%;
  height: 100%;
  min-height: rem(343);

  @include larger-than($mantine-breakpoint-desktop-sm) {
    position: absolute;
    top: rem(-300);
    right: 0;
  }
}
