@import 'mantine';
.button {
  appearance: none;
  display: inline-flex;
  align-items: center;
  border: 0;
  padding: 0 rem(8);
  font-size: rem(16);
  font-weight: 500;
  height: rem(40);
  box-sizing: border-box;
  line-height: rem(40);
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  max-width: 100%;

  &:disabled,
  &[aria-disabled='true'] {
    cursor: default;
  }

  &:hover {
    text-decoration: none;
  }

  svg {
    flex-shrink: 0;
  }

  &-primary {
    background-color: var(--mantine-color-blue-1);
    color: var(--mantine-color-gray-1);

    &:hover {
      background-color: var(--mantine-color-blue-6);
    }

    &:active {
      background-color: var(--mantine-color-blue-6);
      color: var(--mantine-color-gray-6);
    }

    &:disabled,
    &[aria-disabled='true'] {
      background-color: var(--mantine-color-gray-4);
      color: var(--mantine-color-gray-6);
    }
  }

  &-secondary {
    background-color: var(--mantine-color-yellow-0);
    color: var(--mantine-color-gray-9);

    &:hover {
      background-color: var(--mantine-color-yellow-5);
    }

    &:active {
      background-color: var(--mantine-color-yellow-5);
      color: var(--mantine-color-blue-4);
    }

    &:disabled,
    &[aria-disabled='true'] {
      background-color: var(--mantine-color-gray-4);
      color: var(--mantine-color-gray-6);
    }
  }

  &-text {
    background-color: transparent;
    padding: 0;
    color: var(--mantine-color-blue-0);

    &:hover {
      text-decoration: underline;
    }

    &:active {
      color: var(--mantine-color-gray-9);
      text-decoration: none;
    }

    &:disabled,
    &[aria-disabled='true'] {
      color: var(--mantine-color-gray-6);
      text-decoration: none;
    }
  }

  &-text-secondary {
    background-color: transparent;
    padding: 0;
    color: var(--mantine-color-yellow-0);

    &:hover {
      text-decoration: underline;
    }

    &:active {
      color: var(--mantine-color-gray-0);
      text-decoration: none;
    }

    &:disabled,
    &[aria-disabled='true'] {
      color: var(--mantine-color-gray-6);
      text-decoration: none;
    }
  }

  &-text-inverted {
    background-color: transparent;
    padding: 0;
    color: var(--mantine-color-gray-0);

    &:hover {
      text-decoration: underline;
    }

    &:active {
      color: var(--mantine-color-gray-6);
      text-decoration: none;
    }

    &:disabled,
    &[aria-disabled='true'] {
      color: #acaeb5;
      text-decoration: none;
    }
  }
}

.big {
  height: rem(56);
  line-height: rem(56);
}

.full {
  height: rem(56);
  line-height: rem(56);
  width: 100%;

  div {
    margin-left: auto;
    margin-right: auto;
  }
}

.fullOnMobile {
  height: rem(56);
  line-height: rem(56);
  width: 100%;

  div {
    margin-left: auto;
    margin-right: auto;
  }

  @include larger-than($mantine-breakpoint-desktop-sm) {
    width: auto;
  }
}

.shadow {
  box-shadow: 0 rem(8) rem(8) rem(4) rgba(8 13 29 / 10%);
}

.contents {
  white-space: nowrap;
  overflow: hidden;
}
