@import 'mantine';
.hero {
  position: relative;
  overflow: hidden;
  height: calc(
    100vh - rem(72) - rem(100)
  ); // minus top bars and some space at the bottom

  display: flex;
  text-align: center;
  color: var(--mantine-color-white);
  background: radial-gradient(
      54.64% 67.3% at 51.55% 103.87%,
      var(--mantine-color-blue-0, #0039a4) 26.5%,
      transparent 100%
    ),
    radial-gradient(
      60.33% 54.31% at 51.55% 100%,
      var(--mantine-color-blue-0, #0039a4) 0%,
      transparent 100%
    ),
    var(--mantine-color-blue-5, #001742);

  @include larger-than($mantine-breakpoint-desktop-sm) {
    align-items: center;
    height: calc(100vh - rem(112) - rem(56)); // minus top bars
    min-height: rem(600);
    max-height: 100vw;
  }
}

.heroBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.heroContent {
  width: 100%;
}

.hiddenWrapper {
  overflow: hidden;
}
