@import 'mantine';
.mobileNavBox {
  padding-left: rem(16);
  padding-right: rem(16);
  margin-bottom: rem(16);
}

.mobileNavBoxItem {
  display: flex;
  align-items: center;
  padding: rem(8) 0;
  border-bottom: 1px solid var(--mantine-color-gray-5);

  &:last-child {
    border-bottom: none;
  }
}

.mobileNavBoxItemLabel {
  flex-grow: 1;
  margin-left: rem(8);
}
