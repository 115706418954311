@import 'mantine';
.wrapper {
  margin-top: rem(24);
  display: flex;
  flex-direction: column;

  @include larger-than($mantine-breakpoint-desktop-sm) {
    margin: 0;
    flex-direction: row;
    align-items: stretch;

    & > * {
      flex-basis: 20%;
    }
  }
}

.service {
  padding-block: rem(24);
  border-top: 1px solid var(--mantine-color-gray-6);

  @include larger-than($mantine-breakpoint-desktop-sm) {
    border-top: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: rem(24);
  }
}

.serviceDescription {
  display: flex;
  justify-content: space-between;

  @include larger-than($mantine-breakpoint-desktop-sm) {
    flex-direction: column;
  }
}

.serviceIcon {
  display: flex;
  margin-left: rem(8);

  @include larger-than($mantine-breakpoint-desktop-sm) {
    margin: 0 0 rem(16);
  }
}

.serviceInfo {
  order: -1;

  @include larger-than($mantine-breakpoint-desktop-sm) {
    order: 1;
  }

  h3 {
    @include h5-desktop;
  }
}

.withScalingUp {
  @include larger-than($mantine-breakpoint-desktop-sm) {
    padding: 0;
    display: contents;
  }
}

.scalingUp {
  padding: rem(40) rem(24) rem(24);
  border-radius: var(--mantine-radius-md);
  background: linear-gradient(
      17.31deg,
      #f2f3f7 58.06%,
      rgba(242 243 247 / 0%) 104.33%
    ),
    linear-gradient(
      0deg,
      var(--colors-background-main, #0039a4),
      var(--colors-background-main, #0039a4)
    ),
    url('/scaling-up-data-access-bg.jpg');
  background-position: top right;
  background-size: cover;
  background-blend-mode: hard-light;

  @include larger-than($mantine-breakpoint-desktop-sm) {
    padding: rem(78) rem(24) rem(24);
    flex-basis: 20%;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
