@import 'mantine';
.imagesWrapper {
  width: 100%;
  height: 100%;
}

.globeImage {
  position: absolute;
  top: rem(-90);
  right: rem(0);
  width: 100%;
  height: auto;

  @include larger-than($mantine-breakpoint-desktop-sm) {
    top: rem(-580);
    right: rem(-580);
    width: rem(939);
    height: rem(939);
  }
}

.markerIcon {
  position: absolute;
  top: calc(50% - 20px);
  right: calc(50% - 20px);
  width: 40px;

  @include larger-than($mantine-breakpoint-desktop-sm) {
    top: rem(0);
    right: rem(80);
  }
}

.mapWrapper {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;

  @include larger-than($mantine-breakpoint-desktop-sm) {
    top: rem(20);
    right: rem(100);
    height: rem(480);
    width: rem(480);
  }
}

.mapImage {
  position: absolute;
  right: 0;
  aspect-ratio: 1/1;
  max-height: 100%;
  max-width: 100%;
}

.timeline {
  position: absolute;
  max-width: rem(280);
  bottom: rem(10);
  left: calc(50% - 140px);

  @include larger-than($mantine-breakpoint-desktop-sm) {
    bottom: rem(20);
    left: calc(50% - 205px);
    max-width: rem(410);
  }
}

.layerName,
.coordinates {
  @include h6-desktop;

  position: absolute;
  display: none;
  font-family: var(--font-basically-a-mono), sans-serif;
  color: var(--mantine-color-gray-5);
  font-weight: 600;
}

.layerName {
  @include larger-than($mantine-breakpoint-desktop-sm) {
    display: block;
    top: rem(-30);
    left: rem(0);
  }
}

.coordinates {
  span {
    display: block;
  }

  @include larger-than($mantine-breakpoint-desktop-sm) {
    display: block;
    bottom: rem(0);
    right: rem(-82);
  }
}
