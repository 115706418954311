@import 'mantine';
.grid {
  flex: 1;
  margin-inline: rem(16);
  max-width: rem(408);

  @include larger-than(440px) {
    margin-inline: auto;
  }

  @include larger-than($mantine-breakpoint-desktop-sm) {
    max-width: rem(1216);
    margin-inline: rem(32);
  }

  @include larger-than($mantine-breakpoint-desktop) {
    margin-inline: auto;
  }
}
